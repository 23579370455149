export default [
  {
    title: 'Reports',
    icon: 'PieChartIcon',
    action: 'API_B2B_USER',
    resource: 'AccessBothUser',
    children: [
      {
        title: 'Transaction Reports',
        icon: 'DatabaseIcon',
        action: 'API_B2B_USER',
        resource: 'AccessBothUser',
        children: [
          {
            title: 'All Transactions',
            route: 'all-transactions',
            action: 'API_B2B_USER',
            resource: 'AccessBothUser',
          },
          {
            title: 'Test Transactions',
            route: 'test-transactions',
            action: 'API_B2B_USER',
            resource: 'AccessBothUser',
          },
          {
            title: 'Lazada Transactions',
            route: 'all-lazada-transactions',
            action: 'lazada_USER',
            resource: 'AccessBothUser',
          },
          {
            title: 'Export Report History',
            route: 'export-history',
            action: 'API_B2B_USER',
            resource: 'AccessBothUser',
          },
          {
            title: 'Bill Payment',
            route: 'bill-payment',
            action: 'API_B2B_USER',
            resource: 'AccessBothUser',
          },
          {
            title: 'Archive Transactions',
            route: 'archive-transactions',
            action: 'API_B2B_USER',
            resource: 'AccessBothUser',
          },
        ],
      },
      {
        title: 'Summary Report',
        icon: 'PieChartIcon',
        action: 'API_B2B_USER',
        resource: 'AccessBothUser',
        children: [
          {
            title: 'Summary Report',
            route: 'summary-info',
            action: 'API_B2B_USER',
            resource: 'AccessBothUser',
          },
          {
            title: 'Archive Summary Report',
            route: 'archive-summary-info',
            action: 'API_B2B_USER',
            resource: 'AccessBothUser',
          },
          {
            title: 'Wallet Statement',
            route: 'wallet-summary',
            action: 'API_B2B_USER',
            resource: 'AccessBothUser',
          },
        ],
      },
    ],
  },
]
